<template>
  <div class="background">
    <shape-left class="shape-left" />
    <shape-right class="shape-right" />
    <div class="first-overlay" />
    <div class="second-overlay" />
  </div>
</template>

<script>
export default {
  components: {
    ShapeLeft: () => import('@components/common/ShapeLeft'),
    ShapeRight: () => import('@components/common/ShapeRight')
  }
}
</script>

<style lang="scss">
.background {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  & > .shape-left {
    position: absolute;
    left: 0;
    bottom: -120px;
    height: 525px;
  }

  & > .shape-right {
    position: absolute;
    top: -100px;
    right: calc(16vw - 204px);
    height: 400px;
  }

  & > .first-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.05;
    background-image: linear-gradient(
      -135deg,
      rgba(18, 30, 72, 0.9) 0%,
      #121e48 100%
    );
  }

  & > .second-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.05;
    background-image: linear-gradient(
      -134deg,
      rgba(0, 0, 0, 0) 0%,
      #000000 100%
    );
  }
}
</style>
